import {
    email,
    required,
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    paymethod: {
        required
    },
    email1: {
        required: requiredIf('isPaypal'),
        email: (val) => email(val.toLowerCase())
    }
    // email2: {
    //     required: requiredIf('isPaypal'),
    //     isSameEmail(value) {
    //         if (this.$v.paymethod.$model === 'paypal') {
    //             if (this.email1 === value) {
    //                 return true
    //             }
    //             return false
    //         }
    //         return true
    //     }
    // }
};
